.diary-gallery {
  padding: 0;

  &[data-images=""] {
    &[data-videos=""] {
      .left {
        .gallery {
          display: none;
        }
      }

      .photos-counter {
        display: none;
      }
    }
  }

  &[data-images="1"],
  &[data-videos="0"] {
    .left {
      .gallery {
        display: none;
      }
      &.hide{
        display: none;
      }
    }

    .photos-counter {
      display: none;
    }
  }

  &[data-images="0"],
  &[data-videos="1"] {
    .left {
      .gallery {
        display: none;
      }
    }

    .photos-counter {
      display: none;
    }
  }

.synch-carousels {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .right {
    order: -1;
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.14);
    margin-bottom: 20px;

    .item {
      margin: 0;
      padding: 0;

      .caption{
        background: #e94e1c;
        position: absolute;
        bottom: 0;
        width: 100%;
        padding: 11px 15px;
        opacity: 0.7;
      }

      .galleryimgdiary {
        img {
        }
      }

      .galleryvideodiary {
        overflow: hidden;
        height: 0;
        width: 100%;
        padding-top: 60.3%;
        &.diaryimg{
          padding-top: none;
        }
        iframe,
        object,
        embed {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .left {
    overflow: hidden;
    height: auto !important;

    .item {
      border: none;
      margin: 0;
      padding: 0;

      .galleryimgdiary {



        position: relative;
        .darkplay {
          height: 0;
          padding-top: 57%;
          top: 0;
          width: 100%;
          left: 0;
          position: absolute;
          z-index: 10;
          background: rgba(0, 0, 0, 0.1);

          img {

          }
        }

        .thumb-item-frame {
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          height: 0;
          padding-top: 57%;
          top: 0;
          width: 100%;
          left: 0;
          position: absolute;

          img {
            opacity: 0;
          }
        }

        img {
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }
  }

  .gallery {

    display: block;

    .slick-list {
      height: auto !important;
      margin: 0 -10px;
    }

    .slick-slide {
      margin: 0 10px;
      cursor: pointer;
    }
  }

  .slick-slide {
    outline: none;

    img {
      width: 100%;
    }
  }

  .slick-vertical {
    .slick-slide {
      border: none;
    }
  }

}

.photos-counter {
  position: absolute;
  top: 10px;
  right: 0;
  padding: 0 20px;
  color: white;
  background: #292929;
}




}
