.overview-header-wrap {
    padding:65px 0 35px;
    &.min-height{
      min-height: 80vh;
      padding: 12vw 0;
      @media(max-width:900px) {
        min-height: 70vh;
        padding: 12vh 0;
      }
    }
    h1 {
        font-size: 36px;
        color: #FEF7EC;
        letter-spacing: -2.26px;
        line-height: 1.3;
    }

    .row {
        margin:0;
        padding:0;
        @media(max-width:1100px) {
          display: block;
        }
        &.full{
          display: block;
        }
        .col {
          padding:0;
          margin:0;
          &:nth-child(1) {
            flex-grow: 1;
            padding-right: 4%;
          }
          &.full{
            width: 98%;
          }
          &:nth-child(2) {
            max-width:50%;
            text-align: right;
            .select_style{
              width: calc(50% - 10px);
              margin-left: 10px;
            }
            @media(max-width:1100px) {
              max-width:100%;
              text-align: left;
              .select_style{
                width: calc(50% - 15px);
                &:nth-child(1) {
                  margin-right: 7.5px;
                  margin-left: 0;
                }
                &:nth-child(2) {
                  margin-left: 7.5px;
                  float: right;
                }
              }
            }
            @media(max-width:800px) {
              .select_style{
                width: 100%;
                &:nth-child(1) {
                  margin-right: 0;
                }
                &:nth-child(2) {
                  margin-left: 0;
                }
              }
            }
          }
        }
        @media(max-width:600px) {
            flex-direction: column;
            flex-wrap: wrap;
            .col {
                width: 100%;
                flex-basis:auto;
                &:nth-child(1) {
                    flex-grow: 0;
                }
                &:nth-child(2) {
                    flex-grow: 0;
                }
            }
        }
    }


}

.latestnews {
  .news-carousel {
    .row.news-overview {
      a.item {
        margin-bottom:35px;
        flex-grow:0;
      }
    }
  }
}

.news-details {
    padding:70px 0;

    position: relative;
    .inner-wrap {
        max-width:742px;
        h3.date {
            font-size: 11px;
            color: #D7D7D7;
        }
        h1 {
            font-size: 25px;
            color: #FFFFFF;
            letter-spacing: -2.83px;
            line-height: 1.3;
        }

        img{
          margin: 30px 0;
        }
        .embed {
          max-width: 100%;
          text-align: center;
          margin: 0 auto;
          padding: 0 0 50px;
          @media(max-width:1000px) {
            max-width: 90%;
          }
          iframe {
            width: 100% !important;
          }
        }


    }
}

.latestnewstitlerelated {
    border-top: 1px solid  #333333;
    padding-top: 60px;
    padding-bottom: 0;
    position: relative;
}

.latestnewsrelated {
    margin-bottom: 70px;
}

.relatedteamnews{
  margin-top: 0;
}

.newsembed{
  max-width: 100%;
  text-align: center;
  margin: 0 auto;
  padding: 0 0 50px;
  iframe {
    width: 100% !important;
    height: 450px !important;
  }
}
