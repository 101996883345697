

.projectteam_title{
  position: relative;
  margin-top: 100px;
  margin-bottom: 60px;
  padding-right: 40%;
  @media(max-width:880px) {
    padding-right: 20%;
  }
  @media(max-width:565px) {
    padding-right: 20%;
  }
  @media(max-width:505px) {
    padding-right: 0;
  }
  @media(max-width:416px) {
    margin-top: 60px;
    margin-bottom: 30px;
  }
  h1{
    font-size: 56px;
    font-family: $title-font-family;
    width:100%;
    color:#fff;
    @media(max-width:710px) { font-size: 46px; }
    @media(max-width:540px) { font-size: 36px; }
    @media(max-width:416px) { font-size: 26px; }
    span{
      width:100%;
      display: block;
    }
  }
  p{
    color: #fff;
  }
}


.projectteamall{
  padding: 0px 0 60px 0;
  position: relative;
  margin-top: 70px;

  h2{
    color: #08757D;
    font-size: 30px;
  }

  .col{
    flex-basis: unset;
    width: calc(50% - 31px);
    background: #0E0E0E;
    margin: 15px ;
    padding: 0;
    display: flex;
    flex-grow: unset;
    padding: 20px 20px 30px;
    @media(max-width:900px) {
      width: calc(100% - 30px);
    }
    .imgwrap{
      width: 30%;
      height: 0;
      display: inline-block;
      background-size: cover;
      background-position: center;
      padding-top: 30%;
      @media(max-width:805px) {
        padding-top: 30%;
      }
      @media(max-width:597px) {
        padding-top: 40%;
      }
    }

    .textwrap{
      width: calc(70% - 30px);
      display: inline-block;
      margin-left: 30px;
      font-size: 13px;
      h3{
        color: #05A3AE;
        font-size: 20px;
        font-family: $title-font-family;
      }
      h4{
        color: #fff;
        font-size: 10px;
        line-height: 15px;
        font-family: $title-font-family;
        margin-bottom: 15px;
      }
      .contact-title{
        margin-top: 20px;
        margin-bottom: 10px;
      }
      h5{
        line-height: 21px;
        margin-bottom: 10px;
        a {
          color:#fff;
          text-decoration: underline;
        }
        &:before {
          content: '';
          display: block;
          float:left;
          width: 23px;
          height: 20px;
          margin-right: 13px;
          background-repeat: no-repeat;
          background-position: center;
        }
        &.phone{
          &:before {
            background-image: url(/assets/img/ico_phone.svg);
          }
        }
        &.email{
          &:before {
            background-image: url(/assets/img/ico_email.svg);
          }
        }
      }
    }
  }
}

.diarist-arrows{
  margin-top: 30px;
}

.diarist-title{
  padding-left: 13px;
}
