.navbar {
  padding:0;
  left:0;
  top:0;
  width:100%;
  display: flex;
  z-index: 10;
  .top{
    margin:0;
    width:100%;
    padding: 40px 0 0 0;
    .inner-wrap{
      display: flex;
      flex-wrap: nowrap;
      .col {
        padding:0;
        width:auto;
        flex-basis: auto;
        &:nth-child(2) {
          flex-grow: 1;
          text-align: right;
          ul {
            display: inline-block;
            margin:0;
            li {
              display: inline-block;
              text-transform: uppercase;
              font-family:$title-font-family;
              font-size: 12px;
              padding: 0 0 0 30px;
              letter-spacing: 1.42px;
              position: relative;
              @media(max-width:1200px) {
                padding: 0 0 0 2.5rem;
              }
              @media(max-width:1060px) {
                font-size: 11px;
              }

              a {
                color: #fff;
                letter-spacing: -1px;
                &.active {
                    color:#05A3AE;
                }
                &[href="https://culturelab.makingthefuture.eu/"] {
                  background-color: $yellow;
                  color: #161616;

                  padding: 1rem 1.6rem;
                }
              }
            }
          }
          @media(max-width:1000px) {
            ul {
              li {
                &:last-child {
                  padding: 0 0 0 20px;
                }
              }
            }
            .socialmedia{
              display: none;
            }
          }
          @media(max-width:990px) {
            .navbar-nav{
              display: none;
            }
          }
        }
      }
    }
  }
}


/* Mobile Button */
#mobile-burger-btn {
  cursor: pointer;
  display:none;
  height: 38px;
  margin:0;
  text-align:center;
  transition: right 0.5s;
  width: 40px;
  z-index: 100;
  border-radius: 0;
  background:#ee86b8;
  color:white;
  i {
    font-size:28px;
    position: relative;
    top:5px;
    &:nth-child(2) {
      display:none;
    }
  }
  @media(max-width:990px) {
    display: inline-block;
    position: fixed;
    right: 40px;
    top: 40px;
    z-index: 9999;
  }
  &.menu-open {
    //right:calc(15.625em + 20px);
    i {
      &:nth-child(1) { display: none; }
      &:nth-child(2) { display: inline-block; }
    }
  }
}

/* Mobile Button */
#product-burger-btn {
  cursor: pointer;
  display: inline-block;
  height: 38px;
  margin:0;
  text-align:center;
  transition: right 0.5s;
  width: 40px;
  z-index: 100;
  border-radius: 0;
  border-radius: 2px;
  color:white;
  margin-right: 15px;
  i {
    font-size:28px;
    position: relative;
    top:5px;
    &:nth-child(2) {
      display:none;
    }
  }

  &.menu-open {
    //right:calc(15.625em + 20px);
    i {
      &:nth-child(1) { display: none; }
      &:nth-child(2) { display: inline-block; }
    }
  }
}
