footer {
  .bottom{
    background:#161616;
    width: 100%;
    padding: 40px 0;
    .inner-wrap {
      text-align: center;
      p{
        margin-bottom: 9px;
        font-size: 11px;
        a {
          color:#fff;
          text-decoration: underline;
        }
      }
    }
  }
}


.twitter{
  background: #161616;
  padding: 60px 0 2px 0;
  position: relative;
  .twittertitle{

    h2{
      float: left;
      background-image: url(/assets/img/twitter_title.png);
      text-indent: -9999px;
      background-repeat:no-repeat;
      width: 420px;
      height: 29px;
      display: block;
      @media(max-width:600px) {
        width: 100%;
        background-size: contain;
        margin-bottom: 0;
      }
    }
    .twitterslidenav{
      float: right;
      margin-left: 20px;
      z-index: 1;
      position: relative;
      @media(max-width:790px) {
        display: none;
      }
    }

  }
  .twitter_feed{
    margin-top: 40px;
    .item{
      background-size: cover;
      width: calc(50% - 30px) !important;
      margin: 0 15px 30px 15px;
      position: relative;
      padding: 0;
      vertical-align: top;
      @media(max-width:790px) {
        width: calc(100% - 30px) !important;
      }
      a.tweet{
        display: block;
        background: #323232;
        padding: 30px;
        min-height: 160px;
        color: #fff;
        text-decoration: none;
        &:after {
        	content: '';
          position: absolute;
          bottom: 0;
          left: 34px;
          width: 0;
          height: 0;
          border: 10px solid transparent;
          border-top-color: #323232;
          border-bottom: 0px;
          margin-left: -10px;
          margin-bottom: -10px;
        }
      }
      .tweet_details{
        clear: both;
        margin-top: 20px;
        .profileimage{
          overflow: hidden;
          width: 65px;
          height: 65px;
          border-radius: 50%;
          float: left;
          background-size:cover;
          background-position: center;
          background-repeat: no-repeat;
          @media(max-width:600px) {
            width: 45px;
            height: 45px;
          }
          img {
            opacity: 0;
          }
        }
        h5{
          color: #fff;
          font-size: 11px;
          font-family: $title-font-family;
          float: left;
          padding: 24px;
          a {
            color: #fff;

          }
          @media(max-width:600px) {
            padding: 16px 0 16px 16px;
          }
        }
      }


      h3{
        color: #fff;
        font-size: 24px;
        font-family: $title-font-family;
        bottom: 30px;
        width: 60%;
        span{
          width: 100%;
          display: block;
        }
      }
      .btn_position{
        position: absolute;
        bottom: -15px;
        left: 10px;
      }
    }
  }
}

.newsletter{
  background-image: url(/assets/img/bck_footer.jpg);
  background-size: cover;
  padding: 100px 0 140px;
  text-align: center;
  @media(max-width:950px) {
    padding: 90px 0 220px;
  }
  @media(max-width:640px) {
    padding: 90px 0 300px;
  }
  .inner-wrap{
    margin: 0 auto;
    width: 90%;
    max-width: 860px;
    h3{
      color: #EC7CAF;
      font-size: 30px;
      font-family: $title-font-family;
      @media(max-width:640px) {
        font-size: 23px;
        margin-bottom: 30px;
      }
    }

  }
  form{
    input, textarea {
    	width:39%;
      margin: 0;
      margin-right: 15px;
    	padding:14px 15px;
      float: left;
      color: #333;
      @media(max-width:950px) {
        width: calc(50% - 30px);
        margin-top:20px;
      }
      @media(max-width:640px) {
        width: 100% ;
        clear: both;
      }
    }
  }
  .form-button{
    z-index: 1;
    position: relative;
    width: 156px;
    margin: 0;
    float: right;
    @media(max-width:950px) {
      clear: both;
      width: 100%;
      margin-top:20px;
    }
    .btn3d {
      width: 150px;
      padding: 12px 30px 12px 20px;
      margin-left: 0;
      @media(max-width:950px) {
        width: 50%;
      }
      &::before {
        bottom: -4px;
        right: 0px;
      }
      &::after {
        top: 0px;
        left: -3px;
      }
      &.btn_arrow {
        background-position: right 17px top 13px;
      }
    }
  }
}

.partners{
  text-align: center;
  padding: 50px 0 80px 0;
  background: #0E0E0E;
  h3{
    color: #787878;
    font-size: 20px;
    margin-bottom: 30px;
    font-family: $title-font-family;
  }
  .col{
    border: 1px solid #222222;
    padding: 56px;
    margin: 0 7px;
    @media(max-width:1000px) {
      flex-basis: auto;
      width: 33%;
      margin-bottom: 15px;
    }
    @media(max-width:467px) {
      flex-basis: auto;
      width: 100%;
      margin-bottom: 15px;
    }
    .img-wrap{
      display: flex;
      justify-content: center;
      align-items: center;
      background: #fff;
      height: 0;

      img{
        height: auto;
      }
    }

  }
}
