.porfolio-gallery {
  padding: 0;

  &[data-images=""] {
    &[data-videos=""] {
      .left {
        .gallery {
          display: none;
        }
      }

      .photos-counter {
        display: none;
      }
    }
  }

  &[data-images="1"],
  &[data-videos="0"] {
    .left {
      .gallery {
        display: none;
      }
      &.hide{
        display: none;
      }
    }

    .photos-counter {
      display: none;
    }
  }

  &[data-images="0"],
  &[data-videos="1"] {
    .left {
      .gallery {
        display: none;
      }
    }

    .photos-counter {
      display: none;
    }
  }
}

.gallerybuttons {
  border: none;
  outline: none;
  cursor: pointer;
}


.synch-carousels {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .right {
    order: -1;
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.14);
    margin-bottom: 20px;

    .item {
      margin: 0;
      padding: 0;

      .galleryimg {
        overflow: hidden;
        height: 0;
        width: 100%;
        padding-top: 60.3%;
        &.diaryimg{
          padding-top: none;
        }
        img {
          position: absolute;
          top: 0;
          left: 0;
        }
        .caption{
          background: #e94e1c;
          position: absolute;
          bottom: 0;
          width: 100%;
          padding: 11px 15px;
          opacity: 0.7;
        }

        iframe,
        object,
        embed {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .left {
    overflow: hidden;
    height: auto !important;

    .item {
      border: none;
      margin: 0;
      padding: 0;

      .galleryimg {
        overflow: hidden;
        height: 0;
        padding-top: 56.3%;
        position: relative;
        &.diaryimg{
          padding-top: none;
        }

        .darkplay {
          height: 0;
          padding-top: 57%;
          top: 0;
          width: 100%;
          left: 0;
          position: absolute;
          z-index: 10;
          background: rgba(0, 0, 0, 0.1);

          img {
            width: 39.39px;
            height: 39.34px;
            left: 45%;
            top: 32%;
          }
        }

        .thumb-item-frame {
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          height: 0;
          padding-top: 57%;
          top: 0;
          width: 100%;
          left: 0;
          position: absolute;

          img {
            opacity: 0;
          }
        }

        img {
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }
  }

  .gallery {

    display: block;

    .slick-list {
      height: auto !important;
      margin: 0 -10px;
    }

    .slick-slide {
      margin: 0 10px;
      cursor: pointer;
    }
  }

  .slick-slide {
    outline: none;

    img {
      width: 100%;
    }
  }

  .slick-vertical {
    .slick-slide {
      border: none;
    }
  }

}

.photos-counter {
  position: absolute;
  top: 10px;
  right: 0;
  padding: 0 20px;
  color: white;
  background: #292929;
}

.nav-arrows {
  display: flex;
  position: absolute;
  width: 100%;
  top: 33%;
  z-index: 200;

  @media(max-width:480px) {
    top: 27%;
  }

  .arrow-left {
    left: -30px;
    position: absolute;

    &:focus {
      outline: none;
    }
  }

  .arrow-right {
    right: -22px;
    position: absolute;
    left: auto;

    &:focus {
      outline: none;
    }
  }
}

.synch-carousels>* {
  width: 100%;
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: unset;
  margin-right: unset;
}
