h1,h2 {
  font-family:$title-font-family;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 2rem;

}

h1 {
  letter-spacing: 0;
  font-style: normal;
  font-size: 27px;
  line-height: 1.2;
  text-transform: uppercase;
  color: $darkblue;
}

h2 {
  font-size: 42px;
  line-height: 1.2;
  text-transform: uppercase;
  color: $darkblue;
}

p{
  margin-bottom: 2rem;
}


.defaulttext{
  h2{
    font-size: 20px;
    font-family:$title-font-family;
    font-style: normal;
    font-weight: 500;
    line-height: 1.2;
    text-transform: uppercase;
    color: #98CCC3;
    margin-bottom: 20px;
  }
  h3{
    font-size: 16px;
    font-family:$title-font-family;
    font-style: normal;
    font-weight: 500;
    line-height: 1.2;
    text-transform: uppercase;
    color: #98CCC3;
    margin-bottom: 20px;
  }
}
