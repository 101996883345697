$yellow: #90CFC3;
$brown: #E94E1B;
$blue: #1CD3F8;
$angle: 6px;
$angle-o: 3px;



.btn3d {
  top: 0;
  left: 0;
  transition: all .15s linear 0s;
  position: relative;
  display: inline-block;
  padding: 10px 20px;
  background-color: $yellow;
  margin-left: 14px;
  text-transform: uppercase;
  color: #fff;
  font-family: arial;
  letter-spacing: 1px;
  box-shadow: -4px 4px 0 $brown;
  text-decoration: none;
  font-family: $title-font-family;
  font-size: 12px;
  &:hover {
    top: 2px;
    left: -2px;
    box-shadow: -3px 3px 0 $brown;
    &::after {
      top: 1px;
      left: -2px;
      width: $angle-o;
      height: $angle-o;
    }
    &::before {
      bottom: -2px;
      right: 1px;
      width: $angle-o;
      height: $angle-o;
    }
  }
  &::after {
    transition: all .15s linear 0s;
    content: '';
    position: absolute;
    top: 1px;
    left: -2px;
    width: $angle;
    height: $angle;
    background-color: $brown;
    transform: rotate(45deg);
    z-index: -1;
  }
  &::before {
    transition: all .15s linear 0s;
    content: '';
    position: absolute;
    bottom: -3px;
    right: 1px;
    width: $angle;
    height: $angle;
    background-color: $brown;
    transform: rotate(45deg);
    z-index: -1;
  }
}

a.btn3b {
  position: relative;
}

.btn-next {
  background-image:url("/assets/img/arrow_white_right.svg");
  background-repeat: no-repeat;
  background-position: center;
  width: 36px;
  height: 36px;

}
.btn-prev {
  background-image:url("/assets/img/arrow_white_left.svg");
  background-repeat: no-repeat;
  background-position: center;
  width: 36px;
  height: 36px;
}

.moreinfo{
  width: 151px;
  background-position-x: 126px;
  background-size: 8px;
  color: #7D7D7D;
  background-image:url("/assets/img/arrow_grey_right.svg");
  &.btn3d {
    background-color: #161616;
    box-shadow: -4px 4px 0 #7D7D7D;
    border-top: 2px solid #7D7D7D;
    border-right: 2px solid #7D7D7D;
    &:hover {
      box-shadow: -3px 3px 0 #7D7D7D;
      color: #7D7D7D;
      text-decoration: none;
      &::after {
        top: -1px;
        left: -2px;

      }
      &::before {
        bottom: -2px;
        right: -1px;
      }
    }
    &::after {
      background-color: #7D7D7D;
      top: -1px;
      left: -3px;
    }
    &::before {
      background-color: #7D7D7D;
      bottom: -3px;
      right: -1px;
    }
  }
}

.btn_arrow{
  background-size: 7px;
  color: #ffffff;
  background-image:url("/assets/img/arrow_white_right.svg");
  background-repeat: no-repeat;
  background-position: right 19px top 11px;
  padding-right: 37px;
  &:hover{
    color: #fff;
    text-decoration: none;
  }
}

.btn_arrow_left{
  color: #ffffff;
  text-align: center;
  background: #252525;
  font-weight: bold;
  padding: 12px 23px;
  border: 1px solid #333;
  &:hover{
    color: #fff;
    background-color: #151515;
    text-decoration: none;
  }
}

.resource_btn_link{
  margin-bottom: 20px;
  .btn3d{left: -10px;}
}

.btn_dear_diary{
  margin-top: 30px;
  left: -17px;
  position: relative;
}
