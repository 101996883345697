.projectinfo_top {
  padding-top: 100px;
  padding-bottom: 100px;

  @media(max-width:880px) {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .col {
    flex-basis: unset;

    &:nth-child(1) {
      width: 40%;

      @media(max-width:880px) {
        display: none;
      }

      p {
        &:nth-child(1) {
          font-size: 17px;
          font-weight: 600;
          line-height: 30px;
        }
      }
    }

    &:nth-child(2) {
      width: 60%;
      padding-left: 1%;

      @media(max-width:880px) {
        width: 100%;
        padding-left: 15px;
        padding-right: 15px;
      }
    }
  }
}

.project_info_categories {
  padding-bottom: 50px;
}

.projectdetails_title {
  position: relative;
  margin-top: 100px;
  margin-bottom: 60px;

  @media(max-width:416px) {
    margin-top: 60px;
    margin-bottom: 30px;
  }

  h1 {
    font-size: 56px;
    font-family: $title-font-family;
    width: 100%;
    color: #fff;

    @media(max-width:710px) {
      font-size: 46px;
    }

    @media(max-width:540px) {
      font-size: 36px;
    }

    @media(max-width:416px) {
      font-size: 26px;
    }

    span {
      width: 100%;
      display: block;
    }
  }
}

.projectdetails_top {
  .col {
    flex-basis: unset;
    margin-bottom: 10px;

    &:nth-child(1) {
      width: 50%;

      @media(max-width:1000px) {
        width: 100%;
        padding-left: 15px;
        padding-right: 15px;
      }

      p {
        &:nth-child(1) {
          font-size: 17px;
          font-weight: 600;
          line-height: 30px;
        }
      }
    }

    &:nth-child(2) {
      width: 50%;
      padding-left: 1%;

      @media(max-width:1000px) {
        width: 100%;
        margin-top: 50px;
      }
    }
  }
}

.projectteam {
  background: #161616;
  padding: 60px 0;
  position: relative;
  margin-top: 70px;
  margin-top: 160px;
  a{
    .textwhite{
      h3, p, span{
        color: #fff;
        margin-bottom: 1rem;
        &:hover{
          text-decoration: none;
        }
      }
      span{
        text-decoration: underline;
      }
    }
    &:hover{
      text-decoration: none;

    }
  }

  h2 {
    color: #08757D;
    font-size: 30px;
  }

  .col {
    flex-basis: unset;
    width: calc(50% - 31px);
    background: #0E0E0E;
    margin: 15px;
    padding: 0;
    display: flex;
    flex-grow: unset;

    @media(max-width:900px) {
      width: calc(100% - 30px);
    }

    &.width-auto {
      width: auto;
    }

    .imgwrap {
      width: 30%;
      height: 0;
      display: inline-block;
      background-size: cover;
      background-position: center;
      padding-top: 30%;

      @media(max-width:1200px) {
        padding-top: 40%;
        width: 25%;
      }

      @media(max-width:900px) {
        padding-top: 20%;
      }

      @media(max-width:805px) {
        padding-top: 30%;
      }

      @media(max-width:597px) {
        padding-top: 40%;
      }
    }

    .textwrap {
      width: 70%;
      display: inline-block;
      padding: 20px 30px 0 35px;

      @media(max-width:1200px) {
        width: 75%;
      }

      @media(max-width:1135px) {
        padding: 15px 15px 0;
      }

      h3 {
        color: #fff;
        font-size: 17px;
        font-family: $title-font-family;
      }

      h4 {
        color: #fff;
        font-size: 10px;
        font-family: $title-font-family;
        margin-bottom: 20px;
      }

      h5 {
        font-size: 13px;
        line-height: 21px;
        margin-bottom: 10px;

        &:before {
          content: '';
          display: block;
          float: left;
          width: 23px;
          height: 20px;
          margin-right: 13px;
          background-repeat: no-repeat;
          background-position: center;
        }

        &.phone {
          &:before {
            background-image: url(/assets/img/ico_phone.svg);
          }
        }

        &.email {
          &:before {
            background-image: url(/assets/img/ico_email.svg);
          }
        }
      }
    }
  }
}






.relatednewsevents {
  background: #0E0E0E;
  padding: 60px 0;
  position: relative;

  .col-related {
    width: calc(50% - 31px);
    background: #0E0E0E;
    margin: 15px;
    padding: 0 !important;
    position: relative;

    @media(max-width:850px) {
      width: calc(100% - 30px);
    }

    .title {
      color: #EB7AAE;
      font-size: 30px;
      width: 100%;
      display: block;
      font-family: $title-font-family;

      @media(max-width:930px) {
        font-size: 24px;
      }

      &.title-events {
        color: #05A3AE;
      }
    }

  }
}

.related-news-carousel,
.related-event-carousel {
  margin-top: 30px;

  .item {
    height: 0;
    display: flex;
    background-size: cover;
    margin: 0 !important;
    width: 100%;
    padding-top: 56% !important;

    @media(max-width:1135px) {
      padding-top: 60% !important;
    }

    @media(max-width:1010px) {
      padding-top: 80% !important;
    }

    @media(max-width:898px) {
      padding-top: 95% !important;
    }

    @media(max-width:850px) {
      padding-top: 45% !important;
    }

    @media(max-width:650px) {
      padding-top: 55% !important;
    }

    @media(max-width:575px) {
      padding-top: 65% !important;
    }

    @media(max-width:495px) {
      padding-top: 105% !important;
    }

    .imgbck {
      background-size: cover;
      background-position: center;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;

      .overlay {
        background-color: rgba(0, 0, 0, 0.5);
        width: 100%;
        height: 100%;
        padding: 30px;
        position: absolute;
        top: 0;
        left: 0;

        @media(max-width:520px) {
          padding: 20px;
        }

        h5 {
          color: #BFBFBF;
          font-size: 11px;
          font-family: $title-font-family;
          position: absolute;
          top: 90px;

          @media(max-width:980px) {
            top: 80px;
          }
        }

        h3 {
          color: #fff;
          font-size: 20px;
          font-family: $title-font-family;
          position: absolute;
          top: 130px;
          line-height: 30px;
          width: 80%;
          letter-spacing: -3px;
        }

        h6 {
          color: #fff;
          font-size: 11px;
          font-family: "LuloCleanOne-Bold", sans-serif;
          position: absolute;
          bottom: 30px;
          background-image: url(/assets/img/arrow_white_right.svg);
          background-repeat: no-repeat;
          background-position: right;
          padding-right: 19px;
          background-size: 7px;
          padding-top: 2px;
        }

        .related-projects-wrap {
          position: absolute;
          top: 30px;
          left: 30px;
          vertical-align: top;

          h7 {
            vertical-align: top;
            color: #fff;
            font-size: 9px;
            font-family: $title-font-family;
            padding: 9px 14px;
            display: inline-block;
            min-height: 31px;

            span {
              display: none;
            }

            &.bculture-lab {
              background-color: #804A78;
            }

            &.btroubles-art {
              background-color: #808184;
            }

            &.bpartition {
              background-color: #EF4B5D;
            }

            &.bwomen-in-the-archives {
              background-color: #F8AB8F;
            }

            &.bcreative-centeneries {
              background-color: #05A3AE;
            }

            &.bconflict-to-peace {
              background-color: #EA7CB0;
            }

            &.bcultural-fusion {
              background-color: #56C0A6;
            }

            &.bmaking-the-future-oral-archive {
              background-color: #F26F21;
            }

            &.b100-shared-stories {
              background-color: #FDC028;
            }
          }

          &[data-related-project="1"] {
            h7 {
              min-height: none;

              span {
                display: block;
              }

            }
          }
        }
      }
    }
  }
}

.related-event-nav,
.related-news-nav {
  position: absolute;
  right: 26px;
  bottom: 13px;
  text-align: right;
  z-index: 10;
}
