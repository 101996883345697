
.inner-wrap {
  max-width:$container;
  width:90%;
  margin:auto;
}

.inner-wrap-grid{
  max-width:1170px;
  width:90%;
  margin:auto;
  .row {
    display: flex;
    flex-wrap: wrap;
    justify-content: end;
    .item {
      flex-grow: 1;
      width: calc(33% - 30px);
      padding: 30px;
      margin: 0 15px 30px 15px;
      @media(max-width:860px) {
        padding: 20px;
      }
      @media(max-width:749px) {
        width: calc(50% - 30px);
      }
      @media(max-width:550px) {
        width: calc(100% - 30px);
      }
    }
  }
}

.project-strands{
  .item{
    height: 0;
    padding-top: 32.4% !important;
    background-size: cover;

    @media(max-width:749px) {
      padding-top: 42.4% !important;
    }
    @media(max-width:550px) {
      padding-top: 62.4% !important;
    }
    h3{
      color: #fff;
      font-size: 24px;
      font-family: $title-font-family;
      position: absolute;
      bottom: 30px;
      width: 60%;
      span{
        width: 100%;
        display: block;
      }
      @media(max-width:1092px) {
        font-size: 20px;
      }
      @media(max-width:911px) {
        font-size: 16px;
      }
      @media(max-width:860px) {
        bottom: 20px;
      }

    }
  }
}

.latestnewstitle,.upcomingeventstitle{
  margin-bottom: 40px;
  @media(max-width:630px) {
    margin-top: 40px;
    margin-bottom: 0;
  }
  h2{
    color:#EB7AAE;
    font-size: 30px;
    float: left;
    margin-top: 6px;
    @media(max-width:630px) {
      font-size: 22px;
    }
  }
  .newsslidenav{
    float: left;
    margin-left: 20px;
    @media(max-width:830px) {
      display: none;
    }
  }
  .btn-right{
    float: right;
    @media(max-width:666px) {
      display: none;
    }
  }
}

.upcomingeventstitle {
  h2{color:#05A3AE;}
  @media(max-width:630px) {
    margin-top: 60px;
  }
}

.latestnews{
  clear: both;
  &.inner-wrap-grid{
    max-width:1200px;
    width: 95%;
  }
  .news-carousel, .event-carousel{
    margin-top: 30px;
    @media(max-width:630px) {
      margin-top: 10px;
    }
    .item{
      height: 0;
      display: flex;
      padding-top: 32.4% !important;
      background-size: cover;
      width: calc(33% - 27px) !important;
      margin: 0 15px;
      @media(max-width:1220px) {
        padding-top: 35.4% !important;
      }
      @media(max-width:1000px) {
            width: calc(50% - 31px) !important;
            padding-top: 43.4% !important;
      }
      @media(max-width:740px) {
        padding-top: 55% !important;
      }
      @media(max-width:700px) {
        padding-top: 60% !important;
      }
      @media(max-width:630px) {
        width: calc(100% - 30px) !important;
        padding-top: 74% !important;
      }
      .imgbck{
        background-size: cover;
        background-position: center;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        .overlay{
          background-color: rgba(0,0,0,0.5);
          width: 100%;
          height: 100%;
          padding: 30px;
          position: absolute;
          top: 0;
          left: 0;
          h5{
            color: #BFBFBF;
            font-size: 11px;
            font-family: $title-font-family;
            position: absolute;
            top: 90px;

            @media(max-width:980px) {
              top: 80px;
            }
          }
          h3{
            color: #fff;
            font-size: 18px;
            font-family: $title-font-family;
            position: absolute;
            top: 130px;
            line-height: 30px;
            width: 80%;
            letter-spacing: -3px;
            @media(max-width:1120px) {
              font-size: 17px;
              line-height: 23px;
            }
            @media(max-width:1000px) {
              top: 110px;
              font-size: 19px;
              line-height: 26px;
            }
            @media(max-width:700px) {
              width: 80%;
            }
          }
          h6{
            color: #fff;
            font-size: 11px;
            font-family: "LuloCleanOne-Bold",sans-serif;
            position: absolute;
            bottom: 30px;
            background-image: url(/assets/img/arrow_white_right.svg);
            background-repeat: no-repeat;
            background-position: right;
            padding-right: 19px;
            background-size: 7px;
            padding-top: 2px;
          }
        }
      }
    }
  }
}

.related-projects-wrap {
  position: absolute;
  top:30px;
  left:30px;
  vertical-align: top;
  h7{
    vertical-align: top;
    color: #fff;
    font-size: 9px;
    font-family: $title-font-family;
    padding: 9px 14px;
    display: inline-block;
    min-height:31px;
    span {
      display: none;
    }
    &.bculture-lab {background-color: #804A78;}
    &.btroubles-art {background-color: #808184;}
    &.bpartition {background-color: #EF4B5D;}
    &.bwomen-in-the-archives {background-color: #F8AB8F;}
    &.bcreative-centeneries {background-color: #05A3AE;}
    &.bconflict-to-peace {background-color: #EA7CB0;}
    &.bcultural-fusion {background-color: #56C0A6;}
    &.bmaking-the-future-oral-archive {background-color: #F26F21;}
    &.b100-shared-stories {background-color: #FDC028;}
    &.bmaking-the-future {background-color: #e36fa7;}
  }
  &[data-related-project="1"] {
    h7 {
      min-height:0;
      span {
        display: block;
      }

    }
  }
}

.post-details-tags {
  @extend .related-projects-wrap;
  position: relative;
  top:0;
  left:0;
  margin:-5px;
  a {
    color:#fff;
    text-decoration: none;
  }
  h7 {
    margin: 5px;
  }
}


.tculture-lab span{color: #804A78;}
.ttroubles-art span{color: #808184;}
.tpartition span{color: #EF4B5D;}
.twomen-in-the-archives span{color: #F8AB8F;}
.tcreative-centeneries span{color: #05A3AE;}
.tconflict-to-peace span{color: #EA7CB0;}
.tcultural-fusion span{color: #56C0A6;}
.tmaking-the-future-oral-archive span{color: #F26F21;}
.t100-shared-stories span{color: #FDC028;}


.resource-inner {
  max-width: 80%;
  text-align: center;
  margin: 0 auto;
  padding: 0 0 50px;
  @media(max-width:1000px) {
    max-width: 90%;
  }
  iframe {
    width: 100% !important;
  }
}

.margin-bottom{
  margin-bottom: 50px;
}


.grid-item { width: 25%; }
.grid-item--width2 { width: 50%; }

.margintop{
  margin-top: 80px;
}
