table {
  width:100%;
  border: 1px solid #E8E8E8;

  thead {
    tr {
      th {
        background:#013040;
        color:#fff;
        padding:12px 16px;
      }
    }
  }
  tbody {
    tr {
      background: #FFF;
      border-bottom: 1px solid #E8E8E8;
      &:nth-child(odd) {
        background: #F6F7FA;
      }
      td {
        padding:12px 16px;
        text-align:left;
        font-size: 12px;
        &:nth-child(1) {
          text-align:left;
          font-weight:600;
          border-right: 1px solid #E8E8E8;
        }
      }
    }
  }
}
