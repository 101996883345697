.slideshow {
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100%;
  margin-bottom: 0 !important;
  .detail{
    overflow: hidden;
    background-size: cover;
    background-position: center;
    height: 111vh;
    @media(max-width:1188px) {
      height: 106vh;
    }
    .gradient{
      background: linear-gradient(0deg, rgba(28,28,28,1) 0%, rgba(28,28,28,0) 100%);
      height: 111vh;
      position: absolute;
      width: 100%;
      bottom: 0;
    }
    .textarea{
      max-width: $container;
      margin: 0 auto;
      width: 90%;
      position: relative ;
      top: 247px;
      .text{
        width: 38%;
        float: left;
        height: 100%;
        @media(max-width:1188px) {
          line-height: 56px;
          font-size: 52px;
        }
        @media(max-width:870px) {
            width: 48%;
        }
        @media(max-width:692px) {
            width: 98%;
        }

        h2{
          font-family: $title-font-family;
          line-height: 88px;
          letter-spacing: 0.23px;
          font-size: 83px;
          color: #fff;
          text-transform: capitalize;
          margin-bottom: 0;
          @media(max-width:1188px) {
            line-height: 67px;
            font-size: 66px;
          }
          @media(max-width:500px) {
            line-height: 62px;
            font-size: 60px;
          }
        }
        h2.Pink{
          color: #EB7AAE;
        }
        h2.Yellow{
          color: #EEA832;
        }
        h2.Blue{
          color: #98CCC3;
        }
      }
    }
  }
}
.slidenav{
  position: absolute;
  z-index: 20;
  width: 100%;
  top: 554px;
  text-align: right;
  @media(max-width:1188px) {
    top: 439px;
  }
  @media(max-width:692px) {
    top: 530px;
    text-align: left;
  }
  @media(max-width:500px) {
    top: 510px;
    text-align: left;
  }
}
