
form {
	width:100%;
	button.btn {
		display:inline-block;
		border-radius:0;
		padding:12.5px 20px;
		line-height:1;
		font-size:13px;
	}
}
label {
	display: none;
}

input, textarea {
	width:100%;
	border-radius:0;
	margin:3.5px 0;
	border: none;
	font-size: 13px;
	padding:12.5px 15px;
	letter-spacing: 0;
	line-height: 1.35;
	border:1px #DDDDDD solid;
	&.required {
		border:1px solid #8B0000;
	}

	&:placeholder {
		color:rgba(#535353, 1);
	}
	&.form-control {
		border-radius:0;
	}
}
::placeholder { /* Chrome/Opera/Safari */
  color:rgba(#535353, 0.9);
}

:placeholder { /* Firefox 18- */
  color:rgba(#535353, 0.9);
}
textarea {
	min-height:130px
}

input[type="text"],
input[type="email"],
input[type="password"],
textarea {
	-webkit-appearance:none;
}

button {
	width:100%;
}

.dialog-item {
	button {
		margin-top:10px;
	}
}

.validation-msg {
	padding:20px 25px;
	color:#333;
	margin:10px auto;
	text-align:center;
	color: #262626;
	border: 1px solid #E0D8D8;
	border-radius: 3px;
	line-height: 1;
	background:#ffffcc;
	&.error {
		background: #F7E0E0;
	}
	@media(max-width:900px) { padding:15px 20px; }
	@media(max-width:600px) { padding:10px 15px; }
	@media(max-width:450px) { padding:7.5px 10px; }
}

.select_style {
	overflow: hidden;
	display: inline-block;
	position: relative;
	cursor: pointer;
	border-radius: 0;
	background: #90CFC3;
	font-size: 12px;
	color: #FFFFFF;
	font-size: 12px;
	width:100%;
	position:relative;
	padding:11.5px;
	padding-left:20px;
	font-family:$title-font-family;
	font-style: normal;
	font-weight: 500;
	&:after {
		content: "\f107";
		font-family: FontAwesome;
		position: absolute;
		color:#fff;
		top:1.5px;
		right:15px;
		pointer-events: none;
		font-size:25px;
	}
	select {
	  -moz-appearance: none;
	  -webkit-appearance: none;
		appearance:none;
		background:none;
		background:transparent;
		border:none;
		cursor:pointer;
		outline:none;
	  width:100%;
		option { padding-right:45px; }
	}
}

.error-input,
div.error-input {
	border:1px $red solid;
}
.error-msg {
	font-size:14px;
	padding:5px 0;
	display: none;
	color:$red;
	text-align:right;
}

.honeypot { display: none; }
