/* --------------------------------

Primary style

-------------------------------- */
*,
*::after,
*::before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

/* --------------------------------

Patterns - reusable parts of our design

-------------------------------- */
@media only screen and (min-width: 900px) {
  .cd-is-hidden {
    visibility: hidden;
  }
}

/* --------------------------------

Vertical Timeline - by CodyHouse.co

-------------------------------- */

.cd-timeline {
  overflow: hidden;
}

.timelinearea {
  position: relative;
  width: 90%;
  max-width: 1170px;
  margin: 0 auto;

  &::before {
    /* this is the vertical line */
    content: "";
    position: absolute;
    top: 0;
    height: 120%;
    width: 2px;
    background: transparent;
    border: 1px dashed #979797;
    left: 50%;
    margin-left: -1px;
    margin-top: 75px;



    @media only screen and (max-width: 900px) {
      left: 52px;
      margin-left: -1px;
    }

    @media only screen and (max-width: 850px) {
      margin-left: -5px;
    }

    @media only screen and (max-width: 768px) {
      margin-left: -8px;
    }

    @media only screen and (max-width: 700px) {
      margin-left: -9px;
    }

    @media only screen and (max-width: 650px) {
      margin-left: -11px;
    }

    @media only screen and (max-width: 600px) {
      margin-left: -14px;
    }

    @media only screen and (max-width: 556px) {
      margin-left: -40px;
    }
  }

  .month {
    text-align: center;
    z-index: 1;
    width: fit-content;
    position: relative;
    margin: 0 auto;

    @media only screen and (max-width: 900px) {
      margin: 0;
    }

    h2 {
      color: #fff;
      font-size: 20px;
      background-color: #1c1c1c;
      padding: 15px;
      border-radius: 6px;

      @media only screen and (max-width: 556px) {
        padding: 0;
      }
    }
  }
}

.cd-timeline__container {
  position: relative;
  width: 90%;
  max-width: 1170px;
  margin: 0 auto;
  padding: 2em 0;

  @media only screen and (max-width: 556px) {
    margin: 0;
    width: 100%;
  }
}

@media only screen and (min-width: 900px) {
  .cd-timeline {
    padding-top: 3em;
    padding-bottom: 3em;
    margin: 0;
  }

  .cd-timeline__container::before {
    left: 50%;
    margin-left: -1px;
  }
}

.cd-timeline__block {
  position: relative;
  margin: 2em 0;

  @media only screen and (max-width: 556px) {
    margin: 0;
  }
}

.cd-timeline__block:after {
  /* clearfix */
  content: "";
  display: table;
  clear: both;
}

.cd-timeline__block:first-child {
  margin-top: 0;
}

.cd-timeline__block:last-child {
  margin-bottom: 0;
}

@media only screen and (min-width: 900px) {
  .cd-timeline__block {
    margin: 4em 0;
  }
}

.cd-timeline__img {
  position: absolute;
  top: 0;
  left: 0;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: block;

  @media only screen and (max-width: 556px) {
  }
}

.timelinearea {
  .month {
    &:nth-child(1) {
      h2 {
        background: transparent;
      }
    }
  }

  .cd-timeline__block {
    .cd-timeline__img {
      background: white;
    }

    .cd-timeline__main-image {
      box-shadow: -4px 4px 0 white;

      &::after {
        background-color: white;
      }

      &::before {
        background-color: white;
      }
    }

    &.orange {
      .cd-timeline__img {
        background: #e94e1b;
      }

      .cd-timeline__main-image {
        box-shadow: -4px 4px 0 #e94e1b;

        &::after {
          background-color: #e94e1b;
        }

        &::before {
          background-color: #e94e1b;
        }
      }
    }

    &.pink {
      .cd-timeline__img {
        background: #eb7bb0;
      }

      .cd-timeline__main-image {
        box-shadow: -4px 4px 0 #eb7bb0;

        &::after {
          background-color: #eb7bb0;
        }

        &::before {
          background-color: #eb7bb0;
        }
      }
    }

    &.blue {
      .cd-timeline__img {
        background: #8dc9bd;
      }

      .cd-timeline__main-image {
        box-shadow: -4px 4px 0 #8dc9bd;

        &::after {
          background-color: #8dc9bd;
        }

        &::before {
          background-color: #8dc9bd;
        }
      }
    }

    &.pale {
      .cd-timeline__img {
        background: #fddaa4;
      }

      .cd-timeline__main-image {
        box-shadow: -4px 4px 0 #fddaa4;

        &::after {
          background-color: #fddaa4;
        }

        &::before {
          background-color: #fddaa4;
        }
      }
    }
    &.floral-white {
      .cd-timeline__img {
        background: #fff8ee;
      }

      .cd-timeline__main-image {
        box-shadow: -4px 4px 0 #fff8ee;

        &::after {
          background-color: #fff8ee;
        }

        &::before {
          background-color: #fff8ee;
        }
      }
    }
    &.dark-tangerine {
      .cd-timeline__img {
        background: #fdd7a1;
      }

      .cd-timeline__main-image {
        box-shadow: -4px 4px 0 #fdd7a1;

        &::after {
          background-color: #fdd7a1;
        }

        &::before {
          background-color: #fdd7a1;
        }
      }
    }
    &.vivid-orange {
      .cd-timeline__img {
        background: #f7a823;
      }

      .cd-timeline__main-image {
        box-shadow: -4px 4px 0 #f7a823;

        &::after {
          background-color: #f7a823;
        }

        &::before {
          background-color: #f7a823;
        }
      }
    }
    &.cyan {
      .cd-timeline__img {
        background: #90cec3;
      }

      .cd-timeline__main-image {
        box-shadow: -4px 4px 0 #90cec3;

        &::after {
          background-color: #90cec3;
        }

        &::before {
          background-color: #90cec3;
        }
      }
    }
    &.soft-pink {
      .cd-timeline__img {
        background: #eb7bae;
      }

      .cd-timeline__main-image {
        box-shadow: -4px 4px 0 #eb7bae;

        &::after {
          background-color: #eb7bae;
        }

        &::before {
          background-color: #eb7bae;
        }
      }
    }
    &.red {
      .cd-timeline__img {
        background: #e94e1b;
      }

      .cd-timeline__main-image {
        box-shadow: -4px 4px 0 #e94e1b;

        &::after {
          background-color: #e94e1b;
        }

        &::before {
          background-color: #e94e1b;
        }
      }
    }
    &.grey {
      .cd-timeline__img {
        background: #555454;
      }

      .cd-timeline__main-image {
        box-shadow: -4px 4px 0 #555454;

        &::after {
          background-color: #555454;
        }

        &::before {
          background-color: #555454;
        }
      }
    }
    &.dark-cyan {
      .cd-timeline__img {
        background: #005454;
      }

      .cd-timeline__main-image {
        box-shadow: -4px 4px 0 #005454;

        &::after {
          background-color: #005454;
        }

        &::before {
          background-color: #005454;
        }
      }
    }
    &.royal-blue {
      .cd-timeline__img {
        background: #0067f9;
      }

      .cd-timeline__main-image {
        box-shadow: -4px 4px 0 #0067f9;

        &::after {
          background-color: #0067f9;
        }

        &::before {
          background-color: #0067f9;
        }
      }
    }
    &.green {
      .cd-timeline__img {
        background: #006508;
      }

      .cd-timeline__main-image {
        box-shadow: -4px 4px 0 #006508;

        &::after {
          background-color: #006508;
        }

        &::before {
          background-color: #006508;
        }
      }
    }
    &.dirty-orange {
      .cd-timeline__img {
        background: #b45f1b;
      }

      .cd-timeline__main-image {
        box-shadow: -4px 4px 0 #b45f1b;

        &::after {
          background-color: #b45f1b;
        }

        &::before {
          background-color: #b45f1b;
        }
      }
    }
    &.lime-green {
      .cd-timeline__img {
        background: #b4e057;
      }

      .cd-timeline__main-image {
        box-shadow: -4px 4px 0 #b4e057;

        &::after {
          background-color: #b4e057;
        }

        &::before {
          background-color: #b4e057;
        }
      }
    }
    &.purple {
      .cd-timeline__img {
        background: #b45cf8;
      }

      .cd-timeline__main-image {
        box-shadow: -4px 4px 0 #b45cf8;

        &::after {
          background-color: #b45cf8;
        }

        &::before {
          background-color: #b45cf8;
        }
      }
    }
    &.sky-blue {
      .cd-timeline__img {
        background: #6dffff;
      }

      .cd-timeline__main-image {
        box-shadow: -4px 4px 0 #6dffff;

        &::after {
          background-color: #6dffff;
        }

        &::before {
          background-color: #6dffff;
        }
      }
    }
    &.white {
      .cd-timeline__img {
        background: #ffffff;
      }

      .cd-timeline__main-image {
        box-shadow: -4px 4px 0 #ffffff;

        &::after {
          background-color: #ffffff;
        }

        &::before {
          background-color: #ffffff;
        }
      }
    }
    &.yellow {
      .cd-timeline__img {
        background: #ffff69;
      }

      .cd-timeline__main-image {
        box-shadow: -4px 4px 0 #ffff69;

        &::after {
          background-color: #ffff69;
        }

        &::before {
          background-color: #ffff69;
        }
      }
    }
    &.bright-yellow {
      .cd-timeline__img {
        background: #fdff00;
      }

      .cd-timeline__main-image {
        box-shadow: -4px 4px 0 #fdff00;

        &::after {
          background-color: #fdff00;
        }

        &::before {
          background-color: #fdff00;
        }
      }
    }
  }
}

@media only screen and (min-width: 900px) {
  .cd-timeline__img {
    width: 25px;
    height: 25px;
    left: 50%;
    margin-left: -12.5px;
    /* Force Hardware Acceleration */
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }

  .cd-timeline__img.cd-timeline__img--bounce-in {
    visibility: visible;
    -webkit-animation: cd-bounce-1 0.6s;
    animation: cd-bounce-1 0.6s;
  }
}

@-webkit-keyframes cd-bounce-1 {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }

  60% {
    opacity: 1;
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes cd-bounce-1 {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }

  60% {
    opacity: 1;
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.timeline-wrap {
  .cd-timeline__date {
    width: 100%;
    display: block;
    padding: 0;

    @media only screen and (max-width: 556px) {
      padding-bottom: 10px;
    }
  }

  .contentarea {
    display: flex;
    cursor: pointer;
    @media only screen and (max-width: 900px) {
      display: block;
    }

    @media only screen and (max-width: 556px) {
      margin-bottom: 40px;
    }

    .cd-timeline__main-image {
      width: 30%;
      position: relative;
      box-shadow: -4px 4px 0;
      display: inline-block;
      background-color: $yellow;
      height: fit-content;

      @media only screen and (max-width: 556px) {
        width: 50%;
      }

      &::after {
        transition: all 0.15s linear 0s;
        content: "";
        position: absolute;
        top: 1px;
        left: -2px;
        width: $angle;
        height: $angle;

        transform: rotate(45deg);
        z-index: -1;
      }

      &::before {
        transition: all 0.15s linear 0s;
        content: "";
        position: absolute;
        bottom: -3px;
        right: 1px;
        width: $angle;
        height: $angle;
        transform: rotate(45deg);
        z-index: -1;
      }
    }

    .cd-timeline__content {
      width: 80%;

      @media only screen and (max-width: 900px) {
        padding-top: 10px;
        margin: 0;
      }
    }
  }
}

.timeline-title {
  font-size: 16px;
  color: #ffffff;
  line-height: 27px;
  font-weight: 600;
  margin-bottom: 10px;
}

.cd-timeline__content {
  position: relative;
  margin-left: 40px;
  background: transparent;

  // padding: 1em;
  @media only screen and (max-width: 556px) {
  }
}

.cd-timeline__content h2 {
  color: #ffffff;
}

.cd-timeline__content p,
.cd-timeline__read-more,
.cd-timeline__date {
  font-size: 1.3rem;
}

.cd-timeline__content p {
  margin: 1em 0;
  line-height: 1.6;
}

.cd-timeline__read-more,
.cd-timeline__date {
  display: inline-block;
}

.cd-timeline__read-more {
  float: right;
  padding: 0.8em 1em;
  background: #acb7c0;
  color: white;
  border-radius: 0.25em;
}

.cd-timeline__read-more:hover {
  background-color: #bac4cb;
}

.cd-timeline__date {
  float: left;
  padding: 0.8em 0;
  opacity: 0.7;
  font-weight: bold;
}

@media only screen and (min-width: 768px) {
  .cd-timeline__content h2 {
    font-size: 2rem;
  }

  .cd-timeline__content p {
    font-size: 1.6rem;
  }

  .cd-timeline__read-more,
  .cd-timeline__date {
    font-size: 1.4rem;
  }
}

@media only screen and (min-width: 900px) {
  .cd-timeline__content {
    margin-left: 0;
    // padding: 1.6em;
    width: 45%;
    /* Force Hardware Acceleration */
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }

  .cd-timeline__read-more {
    float: left;
  }

  .cd-timeline__date {
    position: absolute;
    top: -40px;
    font-size: 1.6rem;
  }

  .cd-timeline__block:nth-child(odd) {
    .cd-timeline__content {
      float: right;

      .cd-timeline__main-image {
        order: 1;
      }

      .cd-timeline__content {
        order: 2;
        padding-left: 20px;
        padding-right: 0;
      }
    }

    &.left {
      .cd-timeline__content {
        float: left;

        .cd-timeline__main-image {
          order: 2;
        }

        .cd-timeline__content {
          order: 1;
          padding-right: 20px;
          padding-left: 0;
        }

        .cd-timeline__date {
          text-align: right;
        }
      }
    }

    .cd-timeline__read-more {
      float: right;
    }
  }

  .cd-timeline__block:nth-child(even) {
    .cd-timeline__content {
      float: left;

      .cd-timeline__main-image {
        order: 2;
      }

      .cd-timeline__content {
        order: 1;
        padding-right: 20px;
        padding-left: 0;
      }

      .cd-timeline__date {
        text-align: right;
      }
    }

    &.left {
      .cd-timeline__content {
        float: right;

        .cd-timeline__main-image {
          order: 1;
        }

        .cd-timeline__content {
          order: 2;
          padding-left: 20px;
          padding-right: 0;
        }

        .cd-timeline__date {
          text-align: left;
        }
      }
    }
  }

  .cd-timeline__content.cd-timeline__content--bounce-in {
    visibility: visible;
    -webkit-animation: cd-bounce-2 0.6s;
    animation: cd-bounce-2 0.6s;
  }
}

@media only screen and (min-width: 900px) {
  /* inverse bounce effect on odd content blocks */
  .cd-timeline__block:nth-child(odd)
    .cd-timeline__content.cd-timeline__content--bounce-in {
    -webkit-animation: cd-bounce-2-inverse 0.6s;
    animation: cd-bounce-2-inverse 0.6s;
  }
}

@-webkit-keyframes cd-bounce-2 {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100px);
    transform: translateX(-100px);
  }

  60% {
    opacity: 1;
    -webkit-transform: translateX(20px);
    transform: translateX(20px);
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes cd-bounce-2 {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100px);
    transform: translateX(-100px);
  }

  60% {
    opacity: 1;
    -webkit-transform: translateX(20px);
    transform: translateX(20px);
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@-webkit-keyframes cd-bounce-2-inverse {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100px);
    transform: translateX(100px);
  }

  60% {
    opacity: 1;
    -webkit-transform: translateX(-20px);
    transform: translateX(-20px);
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes cd-bounce-2-inverse {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100px);
    transform: translateX(100px);
  }

  60% {
    opacity: 1;
    -webkit-transform: translateX(-20px);
    transform: translateX(-20px);
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

a.btn-read-more {
  color: #fff;
  font-size: 10px;
  font-family: "LuloCleanOne-Bold", sans-serif;
  position: absolute;
  background-image: url(/assets/img/arrow_white_right.svg);
  background-repeat: no-repeat;
  background-position: right;
  padding-right: 19px;
  background-size: 6px;
  padding-top: 2px;
}

.timeline-gallery-wrap {
  position: relative;
}

.zero {
  opacity: 0;
}

.timeline-gallery {
  margin-bottom: 35px;

  img {
    height: 300px;
    margin: 0 auto;
  }
}

.timeline-gallery-thumbs {
  img {
    height: 85px;
  }
}

// sticky date

.sticky-date {
  position: fixed;
  right: 2.5%;
  top: calc(50% - 120px);
  z-index: 999;


  .circles {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    a {
      color: white;
      text-decoration: none;

      &:hover {
        background-color: #08757d;
      }
    }

    &::before {
      content: "";
      position: absolute;
      height: 100%;
      background: transparent;
      border: 1px dashed #979797;
      z-index: 1;
    }
    &.tophalf{
      &::before {
        content: "";
        position: absolute;
        height: 40%;
        background: transparent;
        border: 1px dashed #979797;
        z-index: 1;
        margin-top: 110px;
      }
    }
    &.bottomhalf{
      &::before {
        content: "";
        position: absolute;
        height: 50%;
        background: transparent;
        border: 1px dashed #979797;
        z-index: 1;
      }
    }

    .large-circle {
      width: 70px;
      height: 70px;
      border-radius: 35px;
      border: 1px solid #fff;
      display: flex;
      background-color: #212121;
      position: relative;
      z-index: 2;
      margin: 60px 0;

      h2 {
        font-size: 17px;
        color: white;
        margin: 0 auto;
        align-items: center;
        display: flex;
      }
    }

    .small-circle {
      width: 25px;
      height: 25px;
      border-radius: 12.5px;
      border: 1px solid #fff;
      display: flex;
      background-color: #212121;
      position: relative;
      z-index: 2;

      .fa {
        margin: 0 auto;
        padding: 4px;
      }
    }
  }
}
.centeredarea{
    width: 100%;
    text-align: center;
    margin: 0 auto;
    padding-top: 4rem;
    @media (max-width: 900px) {
      text-align: left;
      padding-left: 4rem;
    }
    @media (max-width: 557px) {
      text-align: left;
      padding-left: 1rem;
    }
}

.fancybox-slide--iframe .fancybox-content {
    width  : 960px;
    height : 1100px;
    max-width  : 80%;
    max-height : 90%;
    margin: 0;
    background: #000;
    @media (max-width: 900px) {
      max-width  : 90%;
      max-height : 96%;
    }
    @media (max-width: 700px) {
      max-width  : 96%;
      max-height : 98%;
    }
}

.fancybox-button {
  top: 0;
  background-color: #91D0C4;
  color: #212121;
}

.fancybox-button, .fancybox-button:visited, .fancybox-button:link {
    color: #000;
}

.diaristtimeline{
  padding-bottom: 8rem;
}

.audioarea{
  z-index: 100;
  width: 100%;
  position: relative;
  padding: 30px 0 40px;
  .newsembed{
    width: 100%;

  }
  h2{
    font-size: 22px;
    color: white;
    margin: 0 auto;
    align-items: center;
    display: flex;
    padding: 20px 0;
  }
}
