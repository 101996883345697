#mobile-nav-items {
  display: block;
  position: absolute;
  top: 0;
  right: -354px;
  @media(max-width:1040px) {
    right:0;
  }
  max-width: 354px;
  width:100vw;
  bottom: 0;
  height: 100%;
  z-index: 50;
  text-align:left;
  background:#242424;
  padding:0;
  .top {
    padding:50px 50px 30px 50px;
    position: fixed;
    img {
      height:77px;
    }
  }
  ul {
    padding:0 50px;
    margin:0;
    li {
      color:#fff;
      font-style: normal;
      font-weight: 400;
      text-transform: uppercase;
      display: block;
      text-align:left;
      font-size: 16px;
      border-bottom: 1px solid rgba(#979797, 0.2);
      font-family:$title-font-family;
      font-style: normal;
      font-weight: 500;
      li {
        padding:5px 0;
      }
      a,span {
        color:#fff;
        display:inline-block;
        line-height: 1.25em;
        padding: 14px 0;
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
        i {
          display: inline-block;
          margin-left:10px;
          position: relative;
          top:-3px;
        }

      }
      a{
        &[href="https://culturelab.makingthefuture.eu/"] {
          background-color: #FFDA3C;
          color: #161616;
          width: 100%;
          text-align: center;
        }
      }
      ul.second {
        display: none;
        margin:5px 0 25px;
        padding:0;
        li {
          border-bottom:0;
          display: flex;
          padding:0;
          text-transform: none;
          margin-bottom:10px;
          span {
            padding:0;
            margin-right:8px;
          }
          a {
            padding:0;
            font-size:13px;
            display: inline-block;
          }
        }
      }
      ul.open {
        display: block;
      }
      &.active {
        color: #76A958;
        span {
          color: #76A958;
        }
        ul.second {
          display: block;
          li {
            a {
              color:#fff;
              &.active {
                text-decoration: underline;
              }
            }
          }
        }
        > a {
          color: #76A958;
        }
      }
    }
  }
  .mid{
    position: fixed;
    top: 150px;
    width: 100%;
    ul{
      width: 364px;
    }
  }
  .btm {
    padding:0 35px 35px;
    margin:15px 0 0;
    div {
      font-size: 12px;
      line-height: 1.6;
      margin:20px 0 0;
      color:#B8B8B8;
      strong {
        text-transform: uppercase;
        color: #76A958;
      }
    }
  }
}
