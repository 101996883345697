
$title-font-family:	"LuloCleanOne-Bold", sans-serif;
$standard-font-family: "montserrat", sans-serif;

$container: 1170px;

$yellow: #FFDA3C;
$darkblue: #1C4973;
$lightblue: #27639B;
$grey: #666666;
$offwhite: #F6F7FA;
$textshadow: 0px 0px 4px rgba(0, 0, 0, 0.67);
$btn-color: #FFDA3C;

$break-small: 400px;
$break-large: 1130px;
$mobile-nav: 995px;

$imgpath: '../../../../assets/img/';

.LuloCleanOne-Bold {
	font-family: LuloCleanOne-Bold;
	font-weight: normal;
	font-style: normal;
}
