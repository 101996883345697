
.home-wrap{
  padding-top: 570px;
  position: relative;
  @media(max-width:1188px) {
    padding-top: 420px;
  }
  @media(max-width:692px) {
    padding-top: 500px;
  }
  p{
    font-size: 16px;
    color: #171717;
    line-height: 1.5;
  }
}

.home_links{
  margin-top: 110px;
  margin-bottom: 70px;
  @media(max-width:790px) {
    margin-top: 80px;
    margin-bottom: 20px;
  }
  .item{
    background-size: cover;
    width: calc(50% - 30px);
    padding: 30px 30px 0 30px;
    margin: 0 15px 30px 15px;
    background: #05A3AE;
    position: relative;
    z-index: 0;
    @media(max-width:860px) {
      padding: 20px 20px 40px;
    }
    @media(max-width:790px) {
      width: 100% !important;
      margin-bottom: 70px !important;
    }
    &:nth-child(2){
      background: #EB7AAE;
    }
    h3{
      color: #fff;
      font-size: 24px;
      font-family: $title-font-family;
      bottom: 30px;
      width: 100%;
    }
    .btn_position{
      position: absolute;
      bottom: -15px;
      left: 10px;
    }
  }
}
